  body {
    font: 14px "Century Gothic", Futura, sans-serif;
    margin: 0%;
    background-color: rgb(117, 103, 134);
  }

  button {
    color: white;
    background-color: inherit;
    outline: inherit;
    border: none;
    cursor: pointer;
  }

  .game {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    height: 100vh;
  }

  .currentMove{
    font-weight: bold;
    color: lightpink7;
  }

  .board-row:after {
    clear: both;
    content: "";
    display: table;
  }

  .square, .winner {
    background-color: rgb(139, 151, 216);
    border: 1px solid rgb(80, 80, 80);
    font-size: 4rem;
    font-weight: bold;
    color: #FFFFFF;
    float:inline-start;
    height: 10rem;
    padding: 0;
    text-align: center;
    width: 10rem;
    transition: 20ms;
  }

  .square:focus {
    outline: none;
  }

  .game-status {
    flex: 1 100%;
    text-align: center;
    margin-bottom: 10px;
    font-size: xx-large;
    color: lightpink;
  }

  .game-info > div {
    width: fit-content;
    min-width: 20vw;
    background-color: rgb(126, 114, 141);
  }

  ol, ul {
    color: rgb(255, 210, 225);
    margin: 0 0 .5rem 0;
    font-size: 1.5rem;
  }

  li > button {
    color: rgb(255, 210, 225);
    font-size: 1rem;
    max-width: fit-content;
  }

  .played {
    cursor: default;
  }

  .winner{
    background-color: Sienna;
  }

  .kbd-navigation .square:focus {
    background: #ddd;
  }

  #reverseButton {
    outline: rgb(66, 58, 77);
    background-color: rgb(66, 58, 77);
    cursor: pointer;
    font-size: 1rem;
  }

  @media screen and (max-width: 750px) {
  .square, .winner {
    font-size: 20vw;
    height: 30vw;
    width: 30vw;
    transition: 50ms;
  }

  .game-status {
    font-size: 14vw;
  }

  .game-info > div {
    width: fit-content;
    min-width: 25vw;
    background-color: rgb(126, 114, 141);
  }

  ul, ol {
    font-size: 5vw;
    padding: 0 2vw 0 8vw;
    max-height: 20vh;
    overflow-y: auto;
  }

  li > button {
    color: rgb(255, 210, 225);
    font-size: 4vw;
    padding: 0 2vw 0 0;
  }

  #reverseButton {
    outline: rgb(66, 58, 77);
    background-color: rgb(66, 58, 77);
    cursor: pointer;
    font-size: 4vw;
    padding: 0;
  }

  .game-info {
    width: fit-content;
    min-width: none;
  }
}